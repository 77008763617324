import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';

const PricingContainer = styled('div')({
  backgroundColor: '#141414',
  padding: '80px 20px',
  textAlign: 'center',
  color: '#fff',
});

const PricingCard = styled(Card)({
  backgroundColor: '#1e1e1e',
  borderRadius: '15px',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.15)',
  color: '#fff',
  transition: 'transform 0.4s, box-shadow 0.4s',
  '&:hover': {
    boxShadow: '0 12px 40px rgba(0, 255, 0, 0.3)',
    transform: 'scale(1.08)',
  },
});

const PricingTitle = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: 'bold',
  marginBottom: '20px',
});

const PricingFeature = styled(Typography)({
  fontSize: '1.1rem',
  marginBottom: '15px',
});

const PricingButton = styled(Button)({
  backgroundColor: '#0f0',
  color: '#000',
  marginTop: '30px',
  padding: '12px 30px',
  borderRadius: '30px',
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#0fff0f',
  },
});

const PricingSection = () => {
  return (
    <PricingContainer>
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom>
          Choose Your Plan
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <PricingCard>
              <CardContent>
                <PricingTitle>Basic</PricingTitle>
                <PricingFeature>1,000 Transactions per Month</PricingFeature>
                <PricingFeature>Standard Fraud Detection</PricingFeature>
                <PricingFeature>Email Support</PricingFeature>
                <Typography variant="h4" style={{ marginTop: '20px' }}>
                  $199/month
                </Typography>
                <PricingButton
                  variant="contained"
                  href="https://buy.stripe.com/14k6pegPp2IJ2AM28y"
                  target="_blank"
                >
                  Get Started
                </PricingButton>
              </CardContent>
            </PricingCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <PricingCard>
              <CardContent>
                <PricingTitle>Pro</PricingTitle>
                <PricingFeature>10,000 Transactions per Month</PricingFeature>
                <PricingFeature>Advanced Fraud Detection</PricingFeature>
                <PricingFeature>Priority Email Support</PricingFeature>
                <Typography variant="h4" style={{ marginTop: '20px' }}>
                  $599/month
                </Typography>
                <PricingButton
                  variant="contained"
                  href="https://buy.stripe.com/00g9Bqcz9gzzdfqdRh"
                  target="_blank"
                >
                  Get Started
                </PricingButton>
              </CardContent>
            </PricingCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <PricingCard>
              <CardContent>
                <PricingTitle>Enterprise</PricingTitle>
                <PricingFeature>Unlimited Transactions</PricingFeature>
                <PricingFeature>AI-Powered Fraud Detection</PricingFeature>
                <PricingFeature>24/7 Dedicated Support</PricingFeature>
                <Typography variant="h4" style={{ marginTop: '20px' }}>
                  Contact Us
                </Typography>
                <PricingButton
                  variant="contained"
                  href="https://hiveforensics.com/contact"
                  target="_blank"
                >
                  Contact Sales
                </PricingButton>
              </CardContent>
            </PricingCard>
          </Grid>
        </Grid>
      </Container>
    </PricingContainer>
  );
};

export default PricingSection;
