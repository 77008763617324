import React from 'react';
// import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import PricingSection from './components/PricingSection';
import Footer from './components/Footer';

const App = () => {
  return (
    <div style={{ backgroundColor: '#0b0b0b', color: '#fff', minHeight: '100vh' }}>
      {/* <Header /> */}
      <HeroSection />
      <FeaturesSection />
      <PricingSection /> {/* Add the PricingSection here */}
      <Footer />
    </div>
  );
};

export default App;
