import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import TransactionSimulation from './TransactionSimulation'; // Import the new component

const HeroContainer = styled(Box)({
  backgroundColor: '#141414', // Matches the background color of the FeaturesSection
  color: '#fff',
  padding: '150px 0',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url("/banner.webp")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  '&::before': { // Gradient at the top to blend with previous section
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '150px',
    background: 'linear-gradient(to top, rgba(20, 20, 20, 0), #141414)',
    zIndex: 1,
  },
  '&::after': { // Gradient at the bottom to blend with next section
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '150px',
    background: 'linear-gradient(to bottom, rgba(20, 20, 20, 0), #141414)',
    zIndex: 1,
  },
});




const PuzzlePiece = styled(motion.div)(({ delay }) => ({
  position: 'absolute',
  width: '200px',
  height: '200px',
  backgroundImage: 'url("/buster.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
  animation: `float ${delay}s ease-in-out infinite`,
  '@keyframes float': {
    '0%': { transform: 'translateY(-10px)' },
    '50%': { transform: 'translateY(10px)' },
    '100%': { transform: 'translateY(-10px)' },
  },
}));

const HeroOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.7)', // Slightly darken the overlay to keep text readable
  zIndex: 1,
});

const HeroText = styled(Typography)({
  zIndex: 2,
  position: 'relative',
  fontFamily: 'monospace',
  textTransform: 'uppercase',
  background: 'linear-gradient(90deg, rgba(0, 200, 0, 0.7), rgba(0, 100, 0, 0.7))',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'inline-block',
  textShadow: '0 0 10px rgba(0, 255, 0, 0.5)',
  fontWeight: 'bold',
});

const SubHeroText = styled(Typography)({
  zIndex: 2,
  position: 'relative',
  fontFamily: 'monospace',
  color: '#cccccc', // Softer gray color for the subtext
  marginTop: '20px',
});

const HeroButton = styled(Button)({
  color: '#0f0',
  borderColor: '#0f0',
  padding: '10px 20px',
  margin: '10px',
  fontSize: '1rem',
  fontWeight: 'bold',
  borderRadius: '25px',
  transition: 'background-color 0.3s, transform 0.3s',
  zIndex: 2,
  position: 'relative',
  '&:hover': {
    backgroundColor: '#0f0',
    color: '#000',
    transform: 'scale(1.1)',
  },
});

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroOverlay />
      <TransactionSimulation /> {/* Add the TransactionSimulation component */}
      <PuzzlePiece delay={5} style={{ top: '20%', left: '10%' }} />
      <PuzzlePiece delay={7} style={{ top: '30%', right: '15%' }} />
      <PuzzlePiece delay={6} style={{ bottom: '25%', left: '20%' }} />
      <PuzzlePiece delay={8} style={{ bottom: '15%', right: '10%' }} />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
        style={{ zIndex: 2, position: 'relative' }}
      >
        <Container maxWidth="sm">
          <HeroText variant="h2" gutterBottom>
            Secure Your Transactions
          </HeroText>
          <SubHeroText variant="h5" paragraph>
            Protect your online payments with real-time fraud detection by Hive Forensics.
          </SubHeroText>
          <Grid container spacing={2} justifyContent="center">
            {/* <Grid item>
              <HeroButton variant="outlined">
                Get Started
              </HeroButton>
            </Grid>
            <Grid item>
              <HeroButton variant="contained" color="secondary">
                Learn More
              </HeroButton>
            </Grid> */}
          </Grid>
        </Container>
      </motion.div>
    </HeroContainer>
  );
};

export default HeroSection;
