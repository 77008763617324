import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { IconButton } from '@mui/material';
import { Facebook, Twitter, LinkedIn, GitHub } from '@mui/icons-material';

const FooterContainer = styled('footer')({
  background: 'linear-gradient(45deg, #0d0d0d, #1a1a1a)',
  color: '#fff',
  padding: '40px 0',
  borderTop: '1px solid #333',
  textAlign: 'center',
  position: 'relative',
  zIndex: 1,
});

const SocialContainer = styled('div')({
  margin: '20px 0',
});

const SocialIconButton = styled(IconButton)({
  color: '#0f0',
  margin: '0 10px',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: '#00ff00',
  },
});

const FooterLink = styled(Link)({
  color: '#fff',
  textDecoration: 'none',
  margin: '0 15px',
  fontSize: '0.875rem',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: '#00ff00',
  },
});

const Footer = () => {
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <SocialContainer>
          <SocialIconButton href="https://www.facebook.com/people/Hive-Forensics-AI/61559411780223/">
            <Facebook />
          </SocialIconButton>
          <SocialIconButton href="https://www.twitter.com/hiveforensics">
            <Twitter />
          </SocialIconButton>
          <SocialIconButton href="https://www.linkedin.com/company/hiveforensics/">
            <LinkedIn />
          </SocialIconButton>
          <SocialIconButton href="https://github.com/HiveForensicsAI/fraud-buster-api">
            <GitHub />
          </SocialIconButton>
        </SocialContainer>
        {/* <Typography variant="body2" gutterBottom>
          <FooterLink href="/">Home</FooterLink>
          <FooterLink href="/about">About Us</FooterLink>
          <FooterLink href="/services">Services</FooterLink>
          <FooterLink href="/contact">Contact</FooterLink>
        </Typography> */}
        <Typography variant="body2">
          {'© '}
          <Link color="inherit" href="https://hiveforensics.com/" style={{ textDecoration: 'none' }}>
            Hive Forensics
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
