import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import InsightsIcon from '@mui/icons-material/Insights';
import SpeedIcon from '@mui/icons-material/Speed';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const FeatureContainer = styled('div')({
  backgroundColor: '#141414',
  color: '#fff',
  padding: '80px 20px',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '150px',
    background: 'linear-gradient(to top, rgba(20, 20, 20, 0), #141414)', // Gradient to blend with the section above
    zIndex: 1,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '150px',
    background: 'linear-gradient(to bottom, rgba(20, 20, 20, 0), #141414)', // Gradient to blend with the section below
    zIndex: 1,
  },
});

const FeatureBox = styled(motion.div)({
  textAlign: 'center',
  padding: '30px',
  borderRadius: '10px',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 20px rgba(0, 255, 0, 0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 6px 30px rgba(0, 255, 0, 0.2)',
  },
});

const FeatureIcon = styled('div')({
  color: '#0f0',
  fontSize: '3.5rem',
  marginBottom: '15px',
});

const BackgroundGlow = styled('div')({
  position: 'absolute',
  top: '-20%',
  left: '-20%',
  width: '140%',
  height: '140%',
  background: 'radial-gradient(circle at center, rgba(0, 255, 0, 0.15), transparent 70%)',
  zIndex: 0,
});

const FeaturesSection = () => {
  return (
    <FeatureContainer>
      <BackgroundGlow />
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Why Choose Fraud Buster API?
          </Typography>
          <Typography variant="subtitle1" align="center" paragraph>
            Our cutting-edge technology provides real-time fraud detection and comprehensive reporting to safeguard your transactions. 
          </Typography>
        </motion.div>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} md={4}>
            <FeatureBox
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.1 }}
            >
              <FeatureIcon>
                <SecurityIcon fontSize="large" />
              </FeatureIcon>
              <Typography variant="h6">Real-Time Detection</Typography>
              <Typography>
                Instantly detect and prevent fraudulent transactions.
              </Typography>
            </FeatureBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FeatureBox
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              whileHover={{ scale: 1.1 }}
            >
              <FeatureIcon>
                <IntegrationInstructionsIcon fontSize="large" />
              </FeatureIcon>
              <Typography variant="h6">Seamless Integration</Typography>
              <Typography>
                Easily integrate with your existing payment systems.
              </Typography>
            </FeatureBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FeatureBox
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              whileHover={{ scale: 1.1 }}
            >
              <FeatureIcon>
                <InsightsIcon fontSize="large" />
              </FeatureIcon>
              <Typography variant="h6">Comprehensive Reporting</Typography>
              <Typography>
                Gain insights with intuitive dashboards and detailed reports.
              </Typography>
            </FeatureBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FeatureBox
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <FeatureIcon>
                <SpeedIcon fontSize="large" />
              </FeatureIcon>
              <Typography variant="h6">High Performance</Typography>
              <Typography>
                Experience fast processing speeds for real-time protection.
              </Typography>
            </FeatureBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FeatureBox
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              whileHover={{ scale: 1.1 }}
            >
              <FeatureIcon>
                <VerifiedUserIcon fontSize="large" />
              </FeatureIcon>
              <Typography variant="h6">Verified Protection</Typography>
              <Typography>
                Ensure your transactions are protected with verified security.
              </Typography>
            </FeatureBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FeatureBox
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.7 }}
              whileHover={{ scale: 1.1 }}
            >
              <FeatureIcon>
                <DataUsageIcon fontSize="large" />
              </FeatureIcon>
              <Typography variant="h6">Data Insights</Typography>
              <Typography>
                Leverage advanced analytics for deeper fraud insights.
              </Typography>
            </FeatureBox>
          </Grid>
        </Grid>
      </Container>
    </FeatureContainer>
  );
};

export default FeaturesSection;
