import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const TransactionContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  zIndex: 0,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TransactionMessage = styled(motion.div)(({ color, top, left }) => ({
  position: 'absolute',
  top: `${top}%`,
  left: `${left}%`,
  transform: 'translate(-50%, -50%)',
  color: color,
  fontSize: '1.25rem', // Increased font size for better visibility
  fontFamily: 'monospace',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  opacity: 0,
  textShadow: `0px 0px 8px ${color}`, // Added glow effect
  padding: '8px 12px',
  borderRadius: '5px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Subtle background for readability
  backdropFilter: 'blur(5px)',
}));

const transactionData = [
  { id: 1, message: 'Transaction Approved', color: '#00FF00' },
  { id: 2, message: 'Transaction Flagged', color: '#FF3333' },
  { id: 3, message: 'Transaction Approved', color: '#00FF00' },
  { id: 4, message: 'Transaction Flagged', color: '#FF3333' },
  { id: 5, message: 'Fraud Detected', color: '#FFD700' }, // New transaction types
  { id: 6, message: 'Payment Verified', color: '#1E90FF' },
  // Add more transactions as needed
];

const getRandomPosition = () => {
  const top = Math.random() * 80 + 10; // Keep within 10% to 90% to avoid edges
  const left = Math.random() * 80 + 10;
  return { top, left };
};

const TransactionSimulation = () => {
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [position, setPosition] = useState({ top: 50, left: 50 });

  useEffect(() => {
    const interval = setInterval(() => {
      const randomTransaction =
        transactionData[Math.floor(Math.random() * transactionData.length)];
      const randomPosition = getRandomPosition();
      setActiveTransaction(randomTransaction);
      setPosition(randomPosition);
    }, 1200); // Faster updates for more dynamic effect

    return () => clearInterval(interval);
  }, []);

  return (
    <TransactionContainer>
      {activeTransaction && (
        <TransactionMessage
          key={activeTransaction.id}
          color={activeTransaction.color}
          top={position.top}
          left={position.left}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.8 }}
        >
          {activeTransaction.message}
        </TransactionMessage>
      )}
    </TransactionContainer>
  );
};

export default TransactionSimulation;
